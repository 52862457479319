export const queriesReducer = (state, action) => {
  if (!action.queryType) {
    if (action.deleteQueries && Array.isArray(action.deleteQueries)) {
      const newState = { ...state }
      for (const q of action.deleteQueries) delete newState[q]
      delete action.deleteQueries
      return { ...newState, ...action }
    }
    return { ...state, ...action }
  } else if (action.queryType === 'delete') {
    const newState = { ...state }
    if (action.queries && Array.isArray(action.queries)) {
      for (const q of action.queries) delete newState[q]
    }
    if (action.query) delete newState[action.query]
    return { ...newState }
  } else if (action.queryType === 'reset')
    return {
      ...(state.page_size && { page_size: state.page_size }),
      ...(state.department && { department: state.department }),
      ...(state.q && { q: state.q }),
      ...(state.sort_by && { sort_by: state.sort_by }),
      ...action.queries
    }
  else if (action.queryType === 'department') {
    if (!action.department) delete state.department
    return {
      ...state,
      ...(action.department && { department: action.department })
    }
  } else if (action.queryType === 'increase_page') {
    return { ...state, page: state.page + 1 }
  }

  return state
}
