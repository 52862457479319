import { MenuItem, ListSubheader, Checkbox, InputBase } from '@mui/material'
import Select from '../SelectUncontrolled'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from '@contexts/translation'

const FiltersElementSelect = ({
  handleQueries = () => null,
  resetPage,
  //   setChipFilters,
  filter,
  index
}) => {
  const { t } = useTranslation()

  const inputRef = useRef()

  const [searchValue, setSearchValue] = useState('')
  const [optionsFiltered, setOptionsFiltered] = useState(filter?.options || [])

  const isMultiple = useMemo(() => filter.multiple || false, [filter?.multiple])

  useEffect(() => {
    if (searchValue?.length) {
      const valueLow = searchValue?.toLowerCase()
      setOptionsFiltered(
        filter?.options.filter(
          (opt, i) =>
            (isMultiple ? i > 0 : true) &&
            (opt.isSubheader || opt?.label?.toLowerCase()?.includes(valueLow))
        )
      )
    } else {
      setOptionsFiltered(filter?.options)
    }
  }, [searchValue, filter?.options, isMultiple])

  return (
    <Select
      name={filter.label}
      label={filter.label}
      fullWidth
      value={filter.value}
      multiple={isMultiple}
      disabled={filter.disabled || false}
      renderValue={
        isMultiple
          ? selected => {
              const retElems = filter.options
                .filter(opt => selected?.includes(opt.value))
                .map(opt => opt.label)
              if (retElems.every(el => typeof el === 'string'))
                return retElems.join(', ')
              return retElems
            }
          : undefined
      }
      onAnimationEnd={() => inputRef.current?.focus()}
      onOpen={() => {
        setSearchValue('')
      }}
      MenuProps={{
        sx: { maxHeight: 500, maxWidth: 300 },
        autoFocus: false
      }}
      data_testid="Select-7fe12e5e-6d32-4d04-8f69-56c9ebd89a66"
    >
      <InputBase
        ref={inputRef}
        sx={{ ml: 2, my: 1, flex: 1 }}
        placeholder={t('search')}
        value={searchValue}
        onChange={e => setSearchValue(e.target.value)}
        onKeyDown={e => e.stopPropagation()}
      />
      {optionsFiltered?.map((option, j) => {
        const valueSelected =
          isMultiple && Array.isArray(filter.value)
            ? filter.value?.includes(option.value)
            : undefined
        const optionValue =
          isMultiple && Array.isArray(filter.value)
            ? valueSelected
              ? filter.value?.filter(val => val !== option.value)
              : [...filter.value, option.value]
            : option.value
        return option.isSubheader ? (
          <ListSubheader key={option.value}>{option.label}</ListSubheader>
        ) : (
          <MenuItem
            key={`${index}-${j}`}
            value={option.value}
            sx={{ whiteSpace: 'break-spaces' }}
            onClick={() => {
              resetPage && resetPage()
              if (
                !option.queryFcn &&
                (option.value === undefined ||
                  option.value === null ||
                  option.value === '')
              ) {
                handleQueries({
                  queryType: 'delete',
                  query: filter.query
                })
              } else {
                if (option.queryFcn) {
                  const queries = option.queryFcn()
                  handleQueries({
                    ...(option.value !== null && {
                      [filter.query]: option.value
                    }),
                    ...queries
                  })
                } else
                  handleQueries({
                    [filter.query]: optionValue
                  })
                // setChipFilters(prev => {
                //   if (prev && prev?.indexOf(filter.query) < 0)
                //     prev.push(filter.query)
                //   return prev
                // })
              }
            }}
          >
            {isMultiple && (j > 0 || Boolean(searchValue)) && (
              <Checkbox checked={valueSelected} sx={{ mr: 1 }} />
            )}
            <span
              style={{
                marginLeft: option.tabulation ? '12px' : ''
              }}
            >
              {option.label}
            </span>
          </MenuItem>
        )
      })}
    </Select>
  )
}

export default FiltersElementSelect
