import { Select, MenuItem, InputLabel, FormControl } from '@mui/material'
import { useTranslation } from '@contexts/translation'

const SelectUncontrolled = ({
  name,
  label,
  isLoading = false,
  children,
  onChangeEvent,
  data,
  fullWidth = false,
  ...props
}) => {
  const { dictionary } = useTranslation()

  return (
    <FormControl fullWidth variant="filled" margin="normal">
      <InputLabel id={`${name}-label`}>
        {isLoading ? dictionary.loading : label}
      </InputLabel>
      <Select
        fullWidth={fullWidth}
        name={name}
        labelId={`${name}-label`}
        value={isLoading ? [] : data}
        onChange={onChangeEvent}
        {...props}
        data_testid="Select-4d67518f-ae8b-4e58-9e20-06e05384b15e"
      >
        {!children?.length ? (
          <MenuItem
            disabled
            value=""
            data-testid="MenuItem-d576dba0-7920-4f45-a30f-acc99f6886d4"
          >
            {dictionary.no_data}
          </MenuItem>
        ) : (
          children
        )}
      </Select>
    </FormControl>
  )
}

export default SelectUncontrolled
