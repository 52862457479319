import { useEffect, useMemo, useState } from 'react'
import {
  IconButton,
  Popover,
  Typography,
  Divider,
  Grid,
  MenuItem,
  Tooltip,
  Stack,
  Button,
  Badge
} from '@mui/material'

import { format } from '@utils/date-fns_functions'
import PropTypes from 'prop-types'

import {
  Close as CloseIcon,
  FilterList,
  RestartAlt as RestartAltIcon
} from '@mui/icons-material'
import DatePicker from '@components/form/DatePicker'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { useForm } from 'react-hook-form'

import { useTranslation } from '@contexts/translation'

import useStyles from '@components/common/Filter/styles'
import { useAuth } from '@contexts/auth'
import { useFetchData } from '@api/fetchData'
import API_URL from '@config/services'
import Select from '../SelectUncontrolled'
import { useRouter } from 'next/router'
// import { usePermissions } from '@hooks/usePermissions'
import formatURL from '@utils/formatURL'
import {
  addDays,
  addMonths,
  addYears,
  endOfMonth,
  endOfYear,
  startOfMonth,
  startOfYear
} from 'date-fns'
// import ChipFilter from './ChipFilter'
// import { TYPES } from '@utils/types.config'
// import unformatDate from '@utils/unformatDate'
import FiltersElementSelect from './FiltersElementSelect'

const FiltersElement = ({
  pageFiltersSaveName = '',
  disabled = false,
  defaultQueries,
  handleQueries,
  resetPage,
  operation = null, // default value || null
  operation_pk = null,
  operations = null, // some endpoints require operations instead of operation
  operation_multiple = null,
  ticket_status = null,
  ticket_priority = null,
  payment_type = null,
  invoice_payment_type = null,
  invoice_status = null,
  expense_is_paid = null,
  expense_category = null,
  supplier = null,
  lease = null,
  tenant = null,
  landlord = null,
  billing_entity = null,
  date = false
}) => {
  const { organizationTeam } = useAuth()
  const { t } = useTranslation()
  const router = useRouter()
  const classes = useStyles()

  const operationId = router?.query?.id

  const [openDatePicker, setOpenDatePicker] = useState(false)
  const [dateValue, setDateValue] = useState('')

  const schema = yup.object().shape({
    start_date: yup.date().nullable().required(t('validation_required_field')),
    end_date: yup
      .date()
      .nullable()
      .required(t('validation_required_field'))
      .min(yup.ref('start_date'), t('validation_end_date'))
  })

  const {
    formState: { errors },
    control,
    handleSubmit
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: { start_date: null, end_date: null }
  })

  const [anchorEl, setAnchorEl] = useState(null)
  const handleOpen = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  const id = open ? 'filter-popover' : undefined

  const { data: operationsList } = useFetchData(
    operation !== null ||
      operation_pk !== null ||
      operations !== null ||
      operation_multiple !== null
      ? formatURL(API_URL.PROJECTS.GET_PROJECT_LIST_PK, { page_size: 9999 })
      : null
  )

  const { data: billingEntitiesList } = useFetchData(
    billing_entity !== null
      ? formatURL(API_URL.BILLING_ENTITIES.GET_BILLING_ENTITIES_LIST, {
          page_size: 9999
        })
      : null
  )

  const { data: suppliersList } = useFetchData(
    supplier !== null
      ? formatURL(API_URL.SUPPLIERS.GET_SUPPLIERS_LIST(), {
          page_size: 9999,
          ...(operationId && { operation: operationId })
        })
      : null
  )
  const { data: expenseCategoriesList } = useFetchData(
    expense_category !== null
      ? formatURL(API_URL.CATEGORIES.GET_CATEGORIES(), {
          is_valid: true,
          ...(operationId && { operation: operationId })
        })
      : null
  )

  const { data: leasesList } = useFetchData(
    lease !== null
      ? formatURL(API_URL.LEASES.GET_LEASES_LIST(), {
          page_size: 9999,
          ...(operationId && { operation: operationId })
        })
      : null
  )

  const { data: tenantsList } = useFetchData(
    tenant !== null
      ? formatURL(API_URL.TENANTS.GET_TENANTS_LIST(), {
          page_size: 9999,
          ...(operationId && { operation: operationId })
        })
      : null
  )

  const { data: landlordsList } = useFetchData(
    landlord !== null
      ? formatURL(API_URL.LANDLORDS.GET_LANDLORDS_LIST(), {
          page_size: 9999
        })
      : null
  )

  /* format data as: [{value, label, handleClick}, {value, label, handleClick}, ...] */
  const filtersList = useMemo(() => {
    const filters = []

    if (operation !== null && operationsList?.results)
      filters.push({
        label: t('filter_label_operations'),
        query: 'operation',
        value: operation || '',
        options: [
          { value: '', label: t('filter_label_all_operations') },
          ...(operationsList?.results.map(({ id, display }) => {
            return { value: id, label: display }
          }) || [])
        ]
      })
    if (operation_pk !== null && operationsList?.results)
      filters.push({
        label: t('filter_label_operations'),
        query: 'operation_pk',
        value: operation_pk || '',
        options: [
          { value: '', label: t('filter_label_all_operations') },
          ...(operationsList?.results.map(({ id, display }) => {
            return { value: id, label: display }
          }) || [])
        ]
      })
    if (operations !== null && operationsList?.results)
      filters.push({
        label: t('filter_label_operations'),
        query: 'operations',
        value: operations || '',
        options: [
          { value: '', label: t('filter_label_all_operations') },
          ...(operationsList?.results.map(({ id, display }) => {
            return { value: id, label: display }
          }) || [])
        ]
      })
    if (operation_multiple !== null && operationsList?.results)
      filters.push({
        label: t('filter_label_operations'),
        query: 'operation',
        value: operation_multiple || [],
        multiple: true,
        options: [
          { value: '', label: t('filter_label_all_operations') },
          ...(operationsList?.results.map(({ id, display }) => {
            return { value: id, label: display }
          }) || [])
        ]
      })
    if (supplier !== null && suppliersList?.results)
      filters.push({
        label: t('filter_label_suppliers'),
        query: 'supplier',
        value: supplier || '',
        options: [
          { value: '', label: t('filter_label_all_suppliers') },
          ...(suppliersList?.results.map(({ id, display }) => {
            return { value: id, label: display }
          }) || [])
        ]
      })
    if (expense_category !== null && expenseCategoriesList?.results)
      filters.push({
        label: t('filter_label_categories'),
        query: 'category',
        value: expense_category || '',
        options: [
          { value: '', label: t('filter_label_all_categories') },
          ...(expenseCategoriesList?.results.map(({ id, display }) => {
            return { value: id, label: display }
          }) || [])
        ]
      })
    if (lease !== null && leasesList?.results)
      filters.push({
        label: t('filter_label_leases'),
        query: 'lease',
        value: lease || '',
        options: [
          { value: '', label: t('filter_label_all_leases') },
          ...(leasesList?.results.map(({ id, display }) => {
            return { value: id, label: display }
          }) || [])
        ]
      })
    if (tenant !== null && tenantsList?.results)
      filters.push({
        label: t('filter_label_tenants'),
        query: 'tenant',
        value: tenant || '',
        options: [
          { value: '', label: t('filter_label_all_tenants') },
          ...(tenantsList?.results.map(({ id, display }) => {
            return { value: id, label: display }
          }) || [])
        ]
      })
    if (landlord !== null && landlordsList?.results)
      filters.push({
        label: t('filter_label_landlords'),
        query: 'landlord',
        value: landlord || '',
        options: [
          { value: '', label: t('filter_label_all_landlords') },
          ...(landlordsList?.results.map(({ id, display }) => {
            return { value: id, label: display }
          }) || [])
        ]
      })

    /* Filters not from API */

    if (ticket_priority !== null)
      filters.push({
        label: t('priority'),
        query: 'priority',
        value: ticket_priority || '',
        options: [
          { value: '', label: t('priority_all') },
          { value: '0urgent', label: t('priority_urgent') },
          { value: '1high', label: t('priority_high') },
          { value: '2medium', label: t('priority_medium') },
          { value: '3low', label: t('priority_low') }
        ]
      })

    if (ticket_status !== null)
      filters.push({
        label: t('status'),
        query: 'status',
        value: ticket_status || '',
        options: [
          { label: t('all_statuses'), value: '' },
          { label: t('ticketing_status_opened'), value: 'opened' },
          { label: t('ticketing_status_reopened'), value: 'reopened' },
          { label: t('ticketing_status_resolved'), value: 'resolved' },
          { label: t('ticketing_status_closed'), value: 'closed' },
          { label: t('ticketing_status_duplicate'), value: 'duplicate' }
        ]
      })

    if (payment_type !== null || invoice_payment_type !== null)
      filters.push({
        label: t('payment_type_label'),
        query: invoice_payment_type !== null ? 'payment_type' : 'type',
        value: invoice_payment_type || payment_type || '',
        options: [
          { label: t('all_types'), value: '' },
          { label: t('payment_type_cash'), value: 'cash' },
          { label: t('payment_type_check'), value: 'check' },
          { label: t('payment_type_card'), value: 'card' },
          { label: t('payment_type_lcr'), value: 'lcr' },
          { label: t('payment_type_transfer'), value: 'transfer' },
          { label: t('payment_type_deposit'), value: 'deposit' },
          { label: t('payment_type_rate'), value: 'rate' }
        ]
      })
    if (expense_is_paid !== null)
      filters.push({
        label: t('status'),
        query: 'is_paid',
        value: expense_is_paid || '',
        options: [
          { value: '', label: t('filter_all_statuses') },
          { value: 'true', label: t('filter_label_paid') },
          { value: 'false', label: t('filter_label_unpaid') }
        ]
      })
    if (invoice_status !== null)
      filters.push({
        label: t('status'),
        query: 'status',
        value: invoice_status || '',
        options: [
          { value: '', label: t('filter_all_statuses') },
          { value: 'not_valid', label: t('filter_label_drafts') },
          { value: 'not_paid', label: t('filter_label_invoices_unpaid') },
          { value: 'partial', label: t('filter_label_invoices_partial') },
          { value: 'late', label: t('filter_label_invoices_late') },
          { value: 'litigious', label: t('filter_label_invoices_litigious') },
          { value: 'paid', label: t('filter_label_invoices_paid') },
          { value: 'archived', label: t('filter_label_invoices_archived') }
        ]
      })
    if (billing_entity !== null && billingEntitiesList?.results)
      filters.push({
        label: t('billing_entity'),
        query: 'billing_entity',
        value: billing_entity || '',
        options: [
          { value: '', label: t('filter_label_all_billing_entities') },
          ...(billingEntitiesList?.results.map(({ id, display }) => {
            return { value: id, label: display }
          }) || [])
        ]
      })
    // if (invoice_status !== null)
    //   filters.push({
    //     label: dictionary.status,
    //     query: 'invoice_status', // special filter that depends on many queries so we use a "fictive" query to respect filter index
    //     value: invoice_status || '',
    //     customValue: true,
    //     options: [
    //       {
    //         value: '',
    //         queryFcn: () => {
    //           handleQueries({
    //             queryType: 'delete',
    //             queries: ['status', 'is_valid', 'is_scheduled']
    //           })
    //           return {}
    //         },
    //         label: dictionary.all
    //       },
    //       {
    //         value: 'drafts',
    //         queryFcn: () => {
    //           handleQueries({
    //             queryType: 'delete',
    //             queries: ['is_valid', 'is_scheduled']
    //           })
    //           return { status: 'not_valid' }
    //         },
    //         label: dictionary.drafts
    //       },
    //       {
    //         value: 'to_send',
    //         queryFcn: () => ({
    //           status: 'not_sent',
    //           is_valid: true,
    //           is_scheduled: false
    //         }),
    //         label: dictionary.Invoicing.to_send
    //       },
    //       {
    //         value: 'scheduled',
    //         queryFcn: () => {
    //           handleQueries({ queryType: 'delete', queries: ['is_valid'] })
    //           return { status: 'not_sent', is_scheduled: true }
    //         },
    //         label: dictionary.invoices_scheduled
    //       },
    //       {
    //         value: 'sent',
    //         queryFcn: () => {
    //           handleQueries({
    //             queryType: 'delete',
    //             queries: ['is_valid', 'is_scheduled']
    //           })
    //           return {
    //             status: [
    //               'not_paid',
    //               'partial',
    //               'late',
    //               'litigious',
    //               'paid',
    //               'canceled'
    //               // 'closed'
    //             ]
    //           }
    //         },
    //         label: dictionary.invoices_status_sent
    //       },
    //       {
    //         value: 'open',
    //         queryFcn: () => {
    //           handleQueries({
    //             queryType: 'delete',
    //             queries: ['is_valid', 'is_scheduled']
    //           })
    //           return { status: ['not_paid', 'partial', 'late', 'litigious'] }
    //         },
    //         label: dictionary.open
    //       },
    //       {
    //         value: 'not_paid',
    //         tabulation: true,
    //         queryFcn: () => {
    //           handleQueries({
    //             queryType: 'delete',
    //             queries: ['is_valid', 'is_scheduled']
    //           })
    //           return { status: ['not_paid'] }
    //         },
    //         label: dictionary.not_paid
    //       },
    //       {
    //         value: 'partial',
    //         tabulation: true,
    //         queryFcn: () => {
    //           handleQueries({
    //             queryType: 'delete',
    //             queries: ['is_valid', 'is_scheduled']
    //           })
    //           return { status: ['partial'] }
    //         },
    //         label: dictionary.partial
    //       },
    //       {
    //         value: 'late',
    //         tabulation: true,
    //         queryFcn: () => {
    //           handleQueries({
    //             queryType: 'delete',
    //             queries: ['is_valid', 'is_scheduled']
    //           })
    //           return { status: ['late'] }
    //         },
    //         label: dictionary.late
    //       },
    //       {
    //         value: 'litigious',
    //         tabulation: true,
    //         queryFcn: () => {
    //           handleQueries({
    //             queryType: 'delete',
    //             queries: ['is_valid', 'is_scheduled']
    //           })
    //           return { status: ['litigious'] }
    //         },
    //         label: dictionary.litigious
    //       },
    //       {
    //         value: 'closed',
    //         queryFcn: () => {
    //           handleQueries({
    //             queryType: 'delete',
    //             queries: ['is_valid', 'is_scheduled']
    //           })
    //           return { status: ['paid', 'canceled', 'closed', 'archived'] }
    //         },
    //         label: dictionary.closed_invoices
    //       },
    //       {
    //         value: 'paid',
    //         tabulation: true,
    //         queryFcn: () => {
    //           handleQueries({
    //             queryType: 'delete',
    //             queries: ['is_valid', 'is_scheduled']
    //           })
    //           return { status: ['paid'] }
    //         },
    //         label: dictionary.paid
    //       },
    //       {
    //         value: 'canceled',
    //         tabulation: true,
    //         queryFcn: () => {
    //           handleQueries({
    //             queryType: 'delete',
    //             queries: ['is_valid', 'is_scheduled']
    //           })
    //           return { status: ['canceled'] }
    //         },
    //         label: dictionary.canceled
    //       },
    //       {
    //         value: 'archived',
    //         tabulation: true,
    //         queryFcn: () => {
    //           handleQueries({
    //             queryType: 'delete',
    //             queries: ['is_valid', 'is_scheduled']
    //           })
    //           return { status: ['archived'] }
    //         },
    //         label: dictionary.archived
    //       }
    //     ]
    //   })

    return filters
  }, [
    handleQueries,
    organizationTeam,
    t,
    operation,
    operation_pk,
    operations,
    operation_multiple,
    operationsList?.results,
    supplier,
    suppliersList?.results,
    expense_category,
    expenseCategoriesList?.results,
    lease,
    leasesList?.results,
    tenant,
    tenantsList?.results,
    landlord,
    landlordsList?.results,
    ticket_priority,
    ticket_status,
    payment_type,
    invoice_payment_type,
    expense_is_paid,
    invoice_status,
    billing_entity,
    billingEntitiesList?.results
  ])

  const datesFilter = [
    { value: '', label: '----------' },
    {
      value: 'today',
      label: t('filter_label_today'),
      start: format(new Date(), 'dd-MM-yyyy'),
      end: format(new Date(), 'dd-MM-yyyy')
    },
    {
      value: 'last_7_days',
      label: t('filter_label_last_7_days'),
      start: format(addDays(new Date(), -7), 'dd-MM-yyyy'),
      end: format(new Date(), 'dd-MM-yyyy')
    },
    {
      value: 'last_30_days',
      label: t('filter_label_last_30_days'),
      start: format(addDays(new Date(), -30), 'dd-MM-yyyy'),
      end: format(new Date(), 'dd-MM-yyyy')
    },
    {
      value: 'last_365_days',
      label: t('filter_label_last_365_days'),
      start: format(addDays(new Date(), -365), 'dd-MM-yyyy'),
      end: format(new Date(), 'dd-MM-yyyy')
    },
    {
      value: 'this_month',
      label: t('filter_label_this_month'),
      start: format(startOfMonth(new Date()), 'dd-MM-yyyy'),
      end: format(endOfMonth(new Date()), 'dd-MM-yyyy')
    },
    {
      value: 'last_month',
      label: t('filter_label_last_month'),
      start: format(startOfMonth(addMonths(new Date(), -1)), 'dd-MM-yyyy'),
      end: format(endOfMonth(addMonths(new Date(), -1)), 'dd-MM-yyyy')
    },
    {
      value: 'this_year',
      label: t('filter_label_this_year'),
      start: format(startOfYear(new Date()), 'dd-MM-yyyy'),
      end: format(endOfYear(new Date()), 'dd-MM-yyyy')
    },
    {
      value: 'last_year',
      label: t('filter_label_last_year'),
      start: format(startOfYear(addYears(new Date(), -1)), 'dd-MM-yyyy'),
      end: format(endOfYear(addYears(new Date(), -1)), 'dd-MM-yyyy')
    },
    {
      value: 'two_years_ago',
      label: t('filter_label_two_years_ago'),
      start: format(startOfYear(addYears(new Date(), -2)), 'dd-MM-yyyy'),
      end: format(endOfYear(addYears(new Date(), -2)), 'dd-MM-yyyy')
    },
    { value: 'custom_dates', label: t('filter_label_custom_dates') }
  ]

  const onSubmitCustomDates = values => {
    handleQueries({
      page: 1,
      start_date: format(values.start_date, 'dd-MM-yyyy'),
      end_date: format(values.end_date, 'dd-MM-yyyy')
    })
  }

  const filtersActivated = useMemo(() => {
    return filtersList?.filter(filter =>
      filter.multiple ? filter.value?.length > 0 : filter.value
    )
  }, [filtersList])
  const filtersActivatedCount = filtersActivated?.length

  //   const chipFiltersActivated = useMemo(() => {
  //     return filtersList?.filter(filter => chipFilters?.includes(filter.query))
  //   }, [chipFilters, filtersList])

  useEffect(() => {
    if (pageFiltersSaveName) {
      let savedFiltersFormatted = {}
      filtersActivated?.forEach(filter => {
        if (!filter.customValue)
          savedFiltersFormatted[filter.query] = filter.value
        else {
          const selectedOpt = filter.options?.find(
            opt => opt.value === filter.value
          )
          if (selectedOpt?.queryFcn) {
            savedFiltersFormatted = {
              ...savedFiltersFormatted,
              [filter.query]: filter.value,
              ...selectedOpt?.queryFcn()
            }
          }
        }
      })
      localStorage.setItem(
        pageFiltersSaveName,
        JSON.stringify(savedFiltersFormatted)
      )
    }
  }, [pageFiltersSaveName, filtersActivated])

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Badge badgeContent={filtersActivatedCount} color="primary">
        <IconButton
          disabled={disabled}
          onClick={handleOpen}
          sx={{
            borderRadius: '12px',
            border: `1px solid #E0E0E0`,
            paddingX: 2,
            backgroundColor: 'white',
            position: 'relative'
          }}
          data-testid="IconButton-f8770ae0-13c2-4cf8-bee8-2ad6a706d4a4"
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <FilterList sx={{ fontSize: 15 }} />
            <Typography
              variant="button"
              textTransform="none"
              data-testid="Typography-8dab8e90-51ec-447f-98a9-f14feb55d25d"
            >
              {t('filters')}
            </Typography>
          </Stack>
        </IconButton>
      </Badge>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className={classes.root}
        anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
        transformOrigin={{ vertical: 'center', horizontal: 'right' }}
        PaperProps={{
          ...(openDatePicker && {
            style: {
              overflowX: 'visible',
              width: '800px',
              maxWidth: 'none'
            }
          })
        }}
      >
        <Grid
          container
          item
          xs={12}
          sx={{ borderRadius: t => t.spacing(1), width: '300%' }}
        >
          <Grid
            item
            xs={12}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            padding={2}
          >
            {defaultQueries && (
              <Tooltip title={t('filter_label_clear')}>
                <IconButton
                  onClick={() =>
                    handleQueries({
                      queryType: 'reset',
                      queries: defaultQueries
                    })
                  }
                  color="warning"
                >
                  <RestartAltIcon color="warning" />
                </IconButton>
              </Tooltip>
            )}

            <Typography variant="overline" color="black">
              {t('filters')}
            </Typography>
            <IconButton onClick={handleClose} color="error">
              <CloseIcon color="error" />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} container display="flex">
            <Grid item container spacing={2} p={2} xs={openDatePicker ? 6 : 12}>
              {filtersList?.map((filter, i) => {
                return (
                  <Grid item xs={12} key={i}>
                    <Tooltip title={filter.tooltip || ''}>
                      <div>
                        <FiltersElementSelect
                          handleQueries={handleQueries}
                          resetPage={resetPage}
                          //   setChipFilters={setChipFilters}
                          filter={filter}
                          index={i}
                        />
                      </div>
                    </Tooltip>
                  </Grid>
                )
              })}

              {date && (
                <Grid item xs={12}>
                  <Select
                    fullWidth
                    name={t('date')}
                    label={t('date')}
                    value={dateValue}
                  >
                    {datesFilter.map(d => {
                      return (
                        <MenuItem
                          key={d.value}
                          value={d.value}
                          onClick={() => {
                            setDateValue(d.value)
                            if (d.value === 'custom_dates') {
                              setOpenDatePicker(true)
                            } else {
                              if (d.value === '')
                                handleQueries({
                                  queryType: 'delete',
                                  queries: ['start_date', 'end_date']
                                })
                              else
                                handleQueries({
                                  start_date: d.start,
                                  end_date: d.end
                                })
                            }
                          }}
                        >
                          {d.label}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </Grid>
              )}
            </Grid>
            {openDatePicker && (
              <Grid item xs={6} p={4}>
                <form onSubmit={handleSubmit(onSubmitCustomDates)} noValidate>
                  <Stack sx={{ height: '100%' }} spacing={3}>
                    <Typography variant="h6" color="text.light" mb={2}>
                      {t('filter_label_custom_dates')}
                    </Typography>

                    <DatePicker
                      required
                      name="start_date"
                      label={t('filter_label_start_date')}
                      variant="outlined"
                      fullWidth
                      control={control}
                      error={Boolean(errors.start_date)}
                      errorMessage={errors.start_date?.message}
                      data_testid="DatePicker-221aa561-d243-4d9b-b681-50ea653fa6fe"
                    />

                    <DatePicker
                      required
                      name="end_date"
                      label={t('filter_label_end_date')}
                      variant="outlined"
                      fullWidth
                      control={control}
                      error={Boolean(errors.end_date)}
                      errorMessage={errors.end_date?.message}
                      data_testid="DatePicker-a7175128-b3b1-4959-b61c-769d89001085"
                    />

                    <Stack direction="row" spacing={2}>
                      <Button
                        type="submit"
                        data-testid="Button-1c42d749-5ae5-40f3-8cc7-58b565a6e7fe"
                      >
                        {t('submit')}
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => setOpenDatePicker(false)}
                        data-testid="Button-31bb13b0-480c-4ac5-aa90-7eb85e31a216"
                      >
                        {t('close')}
                      </Button>
                    </Stack>
                  </Stack>
                </form>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Popover>

      {/* Chip filters */}
      {/* {chipFiltersActivated?.map(filter => {
        const firstOption = filter.options[0]
        const multiple = filter.multiple || false
        const defaultIndex = multiple
          ? filter.value
          : filter.options?.findIndex(opt => opt.value === filter.value)

        return (
          <Box key={filter.query} sx={{ position: 'relative' }}>
            <Tooltip title={filter.tooltip || ''}>
              <div>
                <ChipFilter
                  defaultIndex={defaultIndex}
                  multiple={multiple}
                  label={filter.label}
                  disabled={filter.disabled || false}
                  firstOption={{
                    id: `${filter.query}_0`,
                    label: firstOption?.label,
                    divider: multiple,
                    handleClick: () => {
                      resetPage && resetPage()
                      if (firstOption.queryFcn) {
                        const queries = firstOption.queryFcn()
                        handleQueries({
                          ...(firstOption.value !== null && {
                            [filter.query]: firstOption.value
                          }),
                          ...queries
                        })
                      } else if (firstOption.value) {
                        handleQueries({
                          [filter.query]: firstOption.value
                        })
                      } else {
                        handleQueries({
                          queryType: 'delete',
                          query: filter.query
                        })
                      }
                    }
                  }}
                  options={
                    filter?.options?.slice(1)?.map(opt => ({
                      id: opt.value,
                      label: opt.label,
                      tabulation: opt.tabulation,
                      isSubheader: opt.isSubheader || false,
                      ...(opt.queryFcn && {
                        handleClick: () => {
                          resetPage && resetPage()
                          const queries = opt.queryFcn()
                          handleQueries({
                            ...(opt.value !== null && {
                              [filter.query]: opt.value
                            }),
                            ...queries
                          })
                        }
                      })
                    })) || []
                  }
                  onClickOption={options => {
                    resetPage && resetPage()
                    handleQueries({ [filter.query]: options })
                  }}
                />
              </div>
            </Tooltip>
            <IconButton
              sx={{
                width: 15,
                height: 15,
                position: 'absolute',
                right: -5,
                top: -5
              }}
              onClick={() => {
                resetPage && resetPage()
                if (firstOption.queryFcn) {
                  const queries = firstOption.queryFcn()
                  handleQueries({
                    ...(firstOption.value !== null && {
                      [filter.query]: firstOption.value
                    }),
                    ...queries
                  })
                } else {
                  if (filter.defaultValue) {
                    handleQueries({
                      [filter.query]: filter.defaultValue
                    })
                  } else {
                    handleQueries({
                      queryType: 'delete',
                      query: filter.query
                    })
                  }
                }
                setChipFilters(prev => prev.filter(f => f !== filter.query))
              }}
            >
              <Cancel sx={{ width: 15, height: 15 }} color="disabled" />
            </IconButton>
          </Box>
        )
      })} */}
    </Stack>
  )
}

export default FiltersElement

FiltersElement.propTypes = {
  disabled: PropTypes.bool,
  handleQueries: PropTypes.func
}
