const formatURL = (url, queries, fetchCondition = true) => {
  if (fetchCondition) {
    let params = null
    if (queries) {
      params = Object.keys(queries)
        .map(key => {
          if (queries[key] !== null && queries[key] !== undefined) {
            if (typeof queries[key] === 'object') {
              return queries[key]
                .map(
                  value =>
                    `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
                )
                .join('&')
            }
            return `${encodeURIComponent(key)}=${encodeURIComponent(
              queries[key]
            )}`
          } else return null
        })
        .filter(e => e)
        .join('&')
    }
    return url + (params ? '?' + params : '')
  } else return null
}

export default formatURL
