import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.main,

    '& .MuiPaper-root': {
      minWidth: '300px',
      maxWidth: '400px',

      '& .native-select': {
        width: '100%',
        padding: `${theme.spacing(1)} ${theme.spacing(3)}`,

        '&.active': {
          boxShadow: `inset 2px 0px 0px ${theme.palette.warning.main}`,
          backgroundColor: `${theme.palette.warning.light}`
        },

        '& > .MuiInput-root': {
          width: '100%',

          '&::after, &::before': {
            borderBottom: 'none'
          },

          '& select': {
            borderBottom: 'none',

            '&:focus': {
              backgroundColor: 'transparent'
            }
          }
        }
      }
    }
  }
}))

export default useStyles
